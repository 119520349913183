<div class="mobile-project-container" *transloco="let t">
  <div class="content" [ngClass]="selectedCampaignView == MobileCampaignsViewOptions.MAP ? 'map' : 'list'">
    <div class="header-wrapper">
      <div class="header-container">
        <div class="total-promo-amount" (click)="openCampaignsRewardsBottomSlideIn()">
          {{ activeCampaignsRewardsSum | loyaltyCurrency }}
        </div>
        <div class="detailed-filters">
          <div class="map-list-container">
            <img ngSrc="assets/icons/list-icon.svg" (click)="switchView(campaignsView.LIST)"
                 [ngClass]="{selected: selectedCampaignView == campaignsView.LIST}" alt="list-icon" height="16"
                 width="17">
            <img ngSrc="assets/icons/map-icon.svg" (click)="switchView(campaignsView.MAP)"
                 [ngClass]="{selected: selectedCampaignView == campaignsView.MAP}" alt="list-icon" height="16"
                 width="17">
          </div>
          <button mat-stroked-button class="button-small" (click)="openFilterBottomSlideIn()">
            {{ t('filters.title') }}
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="status-filters-container">
        @if (selectedCampaignUserStatus === CampaignUserStatus.ACTIVE) {
          <button mat-flat-button class="button-small">{{ t('actions.navbar.active') }}</button>
        } @else {
          <button mat-stroked-button class="button-small" (click)="filterByUserStatus(CampaignUserStatus.ACTIVE)">
            {{ t('actions.navbar.active') }}
          </button>
        }
        @if (selectedCampaignUserStatus === CampaignUserStatus.REDEEMED) {
          <button mat-flat-button class="button-small">{{ t('actions.navbar.redeemed') }}</button>
        } @else {
          <button mat-stroked-button class="button-small" (click)="filterByUserStatus(CampaignUserStatus.REDEEMED)">
            {{ t('actions.navbar.redeemed') }}
          </button>
        }
      </div>
    </div>

    @if (selectedCampaignView == MobileCampaignsViewOptions.LIST) {
      <div class="campaigns-list-container">
        @if (selectedCampaignUserStatus == CampaignUserStatus.ACTIVE) {
          <app-actions-previews-list [campaignUserStatus]="selectedCampaignUserStatus"
                                     [campaignsFilter]="campaignsFilter">
          </app-actions-previews-list>
        } @else {
          <app-redeemed-list-stats-card></app-redeemed-list-stats-card>
          <app-actions-previews-list [campaignUserStatus]="selectedCampaignUserStatus"
                                     [campaignsFilter]="campaignsFilter">
          </app-actions-previews-list>
        }
      </div>
    } @else {
      <app-map class="map-container" [campaignUserStatus]="selectedCampaignUserStatus"
               [campaignsFilter]="campaignsFilter"></app-map>
    }
  </div>
</div>
