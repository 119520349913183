import {Component, computed, input} from '@angular/core';
import {HowToRedeemData} from '../../models/how-to-redeem-data';
import {HowToRedeemComponent} from '../../how-to-redeem/how-to-redeem.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Campaign, CampaignUserStatus, getCampaignLocationDisplayString, TimeConditionsType} from 'shared-lib';
import {openMapApp} from 'shared-lib';

@Component({
  selector: 'app-map-marker-details-card',
  standalone: false,

  templateUrl: './map-marker-details-card.component.html',
  styleUrl: './map-marker-details-card.component.scss'
})
export class MapMarkerDetailsCardComponent {

  campaign = input.required<Campaign>();
  campaignUserStatus = input.required<CampaignUserStatus>();

  address = computed(() => {
    return this.campaign().locations![0]!.address
  });

  locationDisplayString = computed<string | undefined>(() => {
    return getCampaignLocationDisplayString(this.campaign());
  });

  protected readonly TimeConditionsType = TimeConditionsType;
  protected readonly CampaignUserStatus = CampaignUserStatus;

  constructor(private bottomSheet: MatBottomSheet) {
  }

  openHowToRedeem(): void {
    const howToRedeemData: HowToRedeemData = {
      rewardAmount: this.campaign().reward,
      merchantName: this.campaign().merchant.name,
      fullAddress: this.locationDisplayString(),
      organizationName: this.campaign().organization.name,
    };

    this.bottomSheet.open(HowToRedeemComponent, {
      data: {...howToRedeemData}
    });
  }

  protected readonly location = location;
  protected readonly openMapApp = openMapApp;
}
