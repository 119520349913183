import {AfterViewInit, Component, effect, ElementRef, input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {filter, finalize, skip, tap} from "rxjs/operators";
import {BehaviorSubject, debounceTime, fromEvent, Subject, takeUntil} from 'rxjs';
import {
  Campaign,
  CampaignsFilter,
  CampaignUserStatus,
  locationDisplayFunction,
  PageableComponent,
  PaginatedData,
  userPosition
} from 'shared-lib';
import {CampaignService} from '../../../services/campaign.service';
import {HowToRedeemComponent} from '../../how-to-redeem/how-to-redeem.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ValueRatingSlideInComponent} from '../../value-rating-slide-in/value-rating-slide-in.component';

@Component({
  selector: 'app-actions-previews-list',
  templateUrl: './actions-previews-list.component.html',
  styleUrl: './actions-previews-list.component.scss',
  standalone: false
})
export class ActionsPreviewsListComponent extends PageableComponent<Campaign, CampaignsFilter> implements OnDestroy, AfterViewInit, OnInit {

  @ViewChild('actionPreviewContainer') containerRef?: ElementRef;

  campaignUserStatus = input.required<CampaignUserStatus>();
  campaignsFilter = input.required<CampaignsFilter>();

  page = 0;
  isRequestInProgress = false;
  private _campaignsFilterChangeSubject = new Subject<CampaignsFilter>();
  private campaignsFilterChange$ = this._campaignsFilterChangeSubject.asObservable();
  private hasMorePages = true;
  private _campaigns = new BehaviorSubject<Campaign[]>([]);
  campaigns$ = this._campaigns.asObservable();

  constructor(private campaignService: CampaignService,
              private bottomSheet: MatBottomSheet) {
    super();
    //We want to skip first/default filter event.
    this.campaignsFilterChange$.pipe(takeUntil(this.destroy$), skip(1))
      .subscribe(() => {
        //Rest pagination state.
        this._campaigns.next([]);
        this.page = 0;
        this.hasMorePages = true;
        this.onPageChange(this.page);
      });

    effect(() => {
      this._campaignsFilterChangeSubject.next(this.campaignsFilter());
    })
  }

  ngOnInit(): void {
    this.dataSource.page$.pipe(takeUntil(this.destroy$)).subscribe(page => {
      const campaigns = this._campaigns.getValue();
      campaigns.push(...page.content);
      this._campaigns.next(campaigns);
      if (this.page >= page.page.totalPages - 1) {
        this.hasMorePages = false;
      }
    });
  }

  ngAfterViewInit() {
    this.setupScrollListener();
  }

  override loadPaginatedData(): PaginatedData<Campaign, CampaignsFilter> {
    return (request, query) => {
      if (this.campaignUserStatus() == CampaignUserStatus.ACTIVE) {
        return this.campaignService.getActiveInRadius(request, userPosition.lat, userPosition.lng, this.campaignsFilter());
      } else {
        return this.campaignService.getRedeemedCampaigns(request, userPosition.lat, userPosition.lng, this.campaignsFilter());
      }
    }
  }

  override ngOnDestroy() {
    this.dataSource.disconnect();
  }

  openValueRatingModal() {
    this.bottomSheet.open(ValueRatingSlideInComponent);
  }

  openHowToRedeemModal(campaign: Campaign) {
    this.bottomSheet.open(HowToRedeemComponent, {
      data: {
        rewardAmount: campaign.reward,
        merchantName: campaign.merchant.name,
        fullAddress: campaign.locations ? locationDisplayFunction(campaign.locations[0]) : '',
        organizationName: campaign.organization.name,
      }
    });
  }

  private setupScrollListener(): void {
    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(100),
        filter(() => this.hasMorePages),
        filter(() => !this.isRequestInProgress),
        filter(() => this.isScrollNearBottom()),
        finalize(() => this.isRequestInProgress = false),
        tap(() => {
          this.loadNextPage();
        })
      )
      .subscribe();
  }

  private isScrollNearBottom(): boolean {
    const container = this.containerRef?.nativeElement;
    if (!container) {
      return false;
    }
    const scrollPosition = container.scrollTop + container.clientHeight;
    const threshold = container.scrollHeight - 300;
    return scrollPosition >= threshold;
  }

  private loadNextPage(): void {
    this.onPageChange(++this.page);
  }
}

