import {Component, computed, effect, input, signal} from '@angular/core';
import {Observable} from 'rxjs';
import {Cluster, MarkerClusterer} from '@angular/google-maps';
import CollisionBehavior = google.maps.CollisionBehavior;
import {
  Campaign,
  CampaignUserStatus,
  Destroyable,
  userPosition,
  CampaignsFilter,
  LoyaltyCurrencyPipe
} from 'shared-lib';
import {CampaignService} from '../../services/campaign.service';


@Component({
  selector: 'app-map',
  standalone: false,
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent extends Destroyable {

  campaignUserStatus = input.required<CampaignUserStatus>();
  campaignsFilter = input.required<CampaignsFilter>();

  mapOptions = {
    disableDefaultUI: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    clickableIcons: false,
    gestureHandling: 'greedy'
  } as google.maps.MapOptions;

  center: google.maps.LatLngLiteral = {lat: userPosition.lat, lng: userPosition.lng};
  zoom = 15;

  userPositionMarker = {
    position: {lat: userPosition.lat, lng: userPosition.lng}
  } as any;

  campaigns = signal<Campaign[]>([]);
  markerCampaignMap!: Map<google.maps.marker.AdvancedMarkerElementOptions, Campaign>;
  campaignMarkersMap!: Map<Campaign, google.maps.marker.AdvancedMarkerElementOptions>;

  selectedMarker?: any;
  selectedCluster?: Cluster;
  selectedMarkerHtmlElement?: any;
  selectedCampaign: Campaign | undefined;

  markers = computed(() => {
    this.markerCampaignMap = new Map();
    this.campaignMarkersMap = new Map();
    return this.campaigns().map(campaign => {
      const beachFlagImg = document.createElement('img');
      beachFlagImg.src = campaign.merchant.logoUrl!;
      beachFlagImg.className = 'map-count-chip';
      const location = campaign.locations![0];
      const markerOptions = {
        id: campaign.locations![0].id,
        collisionBehavior: CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
        position: {
          lat: location.address.latitude,
          lng: location.address.longitude,
        } as google.maps.LatLngLiteral,
        content: this.buildContent(campaign.merchant.logoUrl!, campaign.reward, location.id),
      } as google.maps.marker.AdvancedMarkerElementOptions;
      this.markerCampaignMap.set(markerOptions, campaign);
      this.campaignMarkersMap.set(campaign, markerOptions);
      return markerOptions;
    });
  });

  googleMap!: google.maps.Map;
  googleMarkerCluster!: MarkerClusterer;
  mapMakers = new Map<number, google.maps.marker.AdvancedMarkerElement>();

  constructor(campaignService: CampaignService, private currencyPipe: LoyaltyCurrencyPipe) {
    super();
    effect(() => {
      let campaigns: Observable<Campaign[]>;
      if (this.campaignUserStatus() == CampaignUserStatus.ACTIVE) {
        campaigns = campaignService.getActiveInRadiusFlatLocation(userPosition.lat, userPosition.lng, this.campaignsFilter());
      } else {
        campaigns = campaignService.getAllRedeemedInRadiusForLocation(userPosition.lat, userPosition.lng, this.campaignsFilter())
      }
      this.clearSelection();
      campaigns.subscribe(campaigns => this.campaigns.set(campaigns));
    });
  }

  onMapMarkerClick(event: any, markerOptions: any): void {
    if (!event) {
      return;
    }
    if (markerOptions) {
      this.selectedCampaign = this.markerCampaignMap.get(markerOptions);
    }
    this.selectedMarker = markerOptions;
    this.selectedMarkerHtmlElement?.classList.remove('selected');
    this.selectedMarkerHtmlElement = event.domEvent.target;
    this.selectedMarkerHtmlElement.classList.add('selected');
  }

  onMapClick(): void {
    this.clearSelection();
  }

  onSelectedCampaignChange(campaign: Campaign): void {
    this.selectedMarkerHtmlElement?.classList.remove('selected');
    this.selectedCampaign = campaign;
    const location = campaign.locations![0]!;
    const index = this.campaigns().indexOf(campaign);
    this.selectedMarker = this.mapMakers.get(index)!;

    this.selectedMarkerHtmlElement = (this.selectedMarker.content! as any).children[0];
    this.selectedMarkerHtmlElement.classList.add('selected');

    const cluster = this.getClusterForMarker() as any;
    if (cluster) {
      //If our marker is in cluster, we simulate click on cluster icon to expand and navigate.
      this.selectedCluster = cluster;
      google.maps.event.trigger(cluster.marker, 'click');
    } else {
      //If we have selected cluster and the selected marker is not in it. Mark selected cluster as undefined and zoom out.
      if (this.selectedCluster && !this.isSelectedMarkerInCluster(this.selectedCluster)) {
        this.selectedCluster = undefined
        this.googleMap.setZoom(this.zoom);
        if (cluster) {
          //If our marker is in cluster, we simulate click on cluster icon to expand and navigate.
          this.selectedCluster = cluster;
          google.maps.event.trigger(cluster.marker, 'click');
        }
      }
      this.googleMap.panTo({lat: location.address.latitude, lng: location.address.longitude});
    }
  }

  clearSelection() {
    this.selectedMarkerHtmlElement?.classList.remove('selected');
    this.selectedMarker = undefined;
    this.selectedCampaign = undefined;
  }

  onMarkerInitialized($event: google.maps.marker.AdvancedMarkerElement, index: number): void {
    this.mapMakers.set(index, $event)
  }

  onMapReady($event: google.maps.Map) {
    this.googleMap = $event;
  }

  private getClusterForMarker(): Cluster | undefined {
    return (this.googleMarkerCluster as any).clusters
      .filter((cluster: Cluster) => cluster.markers && cluster.markers.length > 1)
      .find((cluster: Cluster) => {
        return this.isSelectedMarkerInCluster(cluster);
      });

  }

  private isSelectedMarkerInCluster(cluster: Cluster) {
    const index = cluster.markers?.findIndex((marker: any) => marker == this.selectedMarker);
    return index != -1;
  }

  private buildContent(logo: string, reward: number, locationId: string): any {
    const formattedReward = this.currencyPipe.transform(reward);
    const content = document.createElement("div");
    content.classList.add("map-icon-container", "free-money-map-icon-container");
    content.innerHTML = `
        <img id="${locationId}" src="${logo}" alt="logo" priority>
        <div class="reward-chip">${formattedReward}</div>
    `;
    return content;
  }
}
