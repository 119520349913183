import {Component} from '@angular/core';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {CampaignsRewardsListComponent} from '../campaigns-rewards-list/campaigns-rewards-list.component';
import {CampaignService} from '../../services/campaign.service';
import {
  CampaignFilterData,
  CampaignsFilter,
  CampaignsFiltersComponent,
  CampaignUserStatus,
  SortOptions
} from 'shared-lib';

@Component({
  selector: 'app-free-money-layout',
  templateUrl: './free-money-layout.component.html',
  styleUrl: './free-money-layout.component.scss',
  standalone: false
})
export class FreeMoneyLayoutComponent {

  campaignsFilter = {
    sort: SortOptions.MOST_RECENT,
  } as CampaignsFilter;

  campaignsView = MobileCampaignsViewOptions;
  selectedCampaignView = MobileCampaignsViewOptions.LIST;

  activeCampaignsRewardsSum: number | undefined;
  selectedCampaignUserStatus: CampaignUserStatus = CampaignUserStatus.ACTIVE;
  protected readonly MobileCampaignsViewOptions = MobileCampaignsViewOptions;
  protected readonly CampaignUserStatus = CampaignUserStatus;

  constructor(campaignService: CampaignService, private bottomSheet: MatBottomSheet) {
    campaignService.getActiveCampaignsRewardsSum()
      .subscribe({
        next: (result) => {
          this.activeCampaignsRewardsSum = result.rewardsSum;
        }, error: () => {
          this.activeCampaignsRewardsSum = 0;
        }
      });
  }

  filterByUserStatus(status: CampaignUserStatus) {
    this.selectedCampaignUserStatus = status;
  }

  switchView(subMenuOption: MobileCampaignsViewOptions) {
    this.selectedCampaignView = subMenuOption;
  }

  openFilterBottomSlideIn(): void {
    const bottomSheetRef = this.bottomSheet.open(CampaignsFiltersComponent, {
      data: {
        state: this.campaignsFilter
      } as CampaignFilterData
    });

    bottomSheetRef.afterDismissed().subscribe((campaignsFilter) => {
      this.campaignsFilter = campaignsFilter;
    });
  }

  openCampaignsRewardsBottomSlideIn(): void {
    if (this.activeCampaignsRewardsSum) {
      this.bottomSheet.open(CampaignsRewardsListComponent, {
        data: {rewardsSum: this.activeCampaignsRewardsSum}
      });
    }
  }
}

export enum MobileCampaignsViewOptions {
  LIST = 'LIST',
  MAP = 'MAP',
}

