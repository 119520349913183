import {Component} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ClosableBottomSheetComponent} from "shared-lib";

@Component({
  selector: 'app-value-rating-slide-in',
  templateUrl: './value-rating-slide-in.component.html',
  styleUrl: './value-rating-slide-in.component.scss',
  standalone: false
})
export class ValueRatingSlideInComponent extends ClosableBottomSheetComponent {

  constructor(bottomSheet: MatBottomSheet) {
    super(bottomSheet);
  }

}
