<div class="how-to-redeem-directions-container" *transloco="let t" (swipedown)="close()">
  <app-closable-bottom-sheet-handle (close)="close()"></app-closable-bottom-sheet-handle>
  <div class="title">{{ t('mobile.howToRedeem.title') }}</div>

  <div class="subtitle" [innerHTML]="t('mobile.howToRedeem.subtitle',
                    {rewardAmount: data.rewardAmount | loyaltyNumber, merchantName: data.merchantName})">
  </div>

  <div class="step">
    <div class="step-number">1</div>
    @if (data.fullAddress) {
      <div class="step-text" [innerHTML]="t('mobile.howToRedeem.steps.addressText',
                    {merchantName: data.merchantName, fullAddress: data.fullAddress})"></div>
    } @else {
      <div class="step-text" [innerHTML]="t('mobile.howToRedeem.steps.addressTextNoLocation',
                    {merchantName: data.merchantName})"></div>
    }
  </div>

  <div class="step">
    <div class="step-number">2</div>
    <div class="step-text" [innerHTML]="t('mobile.howToRedeem.steps.bankText')"></div>
  </div>

  <div class="step">
    <div class="step-number">3</div>
    <div class="step-text" [innerHTML]="t('mobile.howToRedeem.steps.rewardText',
                    {rewardAmount: data.rewardAmount.toFixed(2), organizationName: data.organizationName})"></div>
  </div>

  <!--    Uncomment when real text is available-->
  <!--    <div class="text">{{ t('mobile.howToRedeem.text') }}</div>-->
</div>
