import {Component, computed, effect, input, output} from '@angular/core';
import {Campaign, CampaignUserStatus} from 'shared-lib';

/**
 * Based on https://medium.com/@su.ede.kovacs/angular-carousel-e6953aa38f77
 */
@Component({
  selector: 'app-map-marker-details-carousel',
  standalone: false,
  templateUrl: './map-marker-details-carousel.component.html',
  styleUrl: './map-marker-details-carousel.component.scss'
})
export class MapMarkerDetailsCarouselComponent {

  campaigns = input.required<Campaign[]>();
  campaignsUserStatus = input.required<CampaignUserStatus>();
  selectedCampaign = input<Campaign>();
  selectedCampaignChange = output<Campaign>();

  componentId: string | undefined = `carousel-id`;

  itemMap = computed<Map<number, Campaign>>(() => {
    return this.createSliderGroups();
  });
  itemKeys = computed<number[]>(() => {
    return Array.from(this.itemMap().keys());
  });

  private _ = effect(() => {
    this.itemMap().forEach((value, key) => {
      if (this.selectedCampaign() && value.id === this.selectedCampaign()!.id &&
        value.locations![0]!.id === this.selectedCampaign()!.locations![0]!.id) {
        this.scrollToElement(key);
      }
    });
  });
  private currentPage: number = 0;


  navToNext(): void {
    if (this.currentPage < this.campaigns().length - 1) {
      this.currentPage = this.currentPage + 1;
      this.navigateToGroup(this.currentPage);
      this.selectedCampaignChange.emit(this.campaigns()[this.currentPage]);
    }
  }

  navToPrev(): void {
    if (this.currentPage >= 1) {
      this.currentPage = this.currentPage - 1;
      this.navigateToGroup(this.currentPage);
      this.selectedCampaignChange.emit(this.campaigns()[this.currentPage]);
    }
  }

  private navigateToGroup(groupId: number): void {
    const sliderGroup: Element | null = document.querySelector(
      `#${this.componentId}-group-${groupId}`
    );

    sliderGroup?.scrollIntoView({
      block: 'nearest',
      inline: 'center',
      behavior: 'smooth',
    });
  }

  private createSliderGroups(): Map<number, Campaign> {
    const itemMap = new Map<number, Campaign>();

    this.campaigns().forEach((campaign: Campaign, index: number) => {
      itemMap.set(index, campaign);
    });
    return itemMap;
  }

  private scrollToElement(index: number): void {
    if (this.currentPage !== index) {
      this.currentPage = index;
      this.navigateToGroup(this.currentPage);
    }
  }

}
