import {Component, signal} from '@angular/core';
import {CampaignService} from '../../services/campaign.service';

@Component({
  selector: 'app-redeemed-list-stats-card',
  templateUrl: './redeemed-list-stats-card.component.html',
  styleUrl: './redeemed-list-stats-card.component.scss',
  standalone: false
})
export class RedeemedListStatsCardComponent {
  amountSaved = signal<number>(0);
  numberOfRedeemedCampaigns = signal<number>(0);
  percentSaved = signal<number>(0);

  constructor(campaignService: CampaignService) {
    campaignService.getUserRedeemedCampaignsStatistics()
      .subscribe(result => {
        if (result && result.redeemedCount > 0) {
          this.amountSaved.set(result.rewardsSum);
          this.numberOfRedeemedCampaigns.set(result.redeemedCount);
          const percentSaved = Math.trunc(Math.min(((result.rewardsSum / result.transactionsSum) * 100), 100));
          this.percentSaved.set(percentSaved);
        }
      });
  }

}
