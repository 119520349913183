import {inject, Injectable, NgModule, provideAppInitializer} from '@angular/core';
import {
  BrowserModule,
  DomSanitizer,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {MatIconRegistry} from '@angular/material/icon';
import {firstValueFrom} from 'rxjs';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import * as Hammer from 'hammerjs';
import {
  ActionCardPreviewComponent,
  addSvgIcons,
  AUTH_CONFIG,
  AuthConfig,
  AuthenticationInterceptor,
  AuthService,
  CampaignCardPromoDetailsComponent,
  ClosableBottomSheetHandleComponent,
  CustomAuthService,
  LoyaltyCurrencyPipe,
  LoyaltyNumberPipe,
  MCardEventsService,
  MerchantAutocompleteComponent,
  MoneyFormatPipe,
  RadioButtonsQuestionComponent,
  TranslocoRootModule
} from 'shared-lib';
import {TranslocoDirective, TranslocoService} from '@jsverse/transloco';
import {FreeMoneyLayoutComponent} from './components/free-money-layout/free-money-layout.component';
import {
  ActionsPreviewsListComponent
} from './components/action-preview-lists/actions-previews-list/actions-previews-list.component';
import {CampaignsRewardsListComponent} from './components/campaigns-rewards-list/campaigns-rewards-list.component';
import {ValueRatingSlideInComponent} from './components/value-rating-slide-in/value-rating-slide-in.component';
import {HowToRedeemComponent} from './components/how-to-redeem/how-to-redeem.component';
import {RedeemedListStatsCardComponent} from './components/redeemed-list-stats-card/redeemed-list-stats-card.component';
import {MapComponent} from './components/map/map.component';
import {
  MapMarkerDetailsCarouselComponent
} from './components/map/map-marker-details-carousel/map-marker-details-carousel.component';
import {
  MapMarkerDetailsCardComponent
} from './components/map/map-marker-details-card/map-marker-details-card.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {CommonModule, CurrencyPipe, NgOptimizedImage} from '@angular/common';
import {AppRoutingModule} from './app.routes';
import {MaterialModule} from './material.module';
import {environment} from '../environments/environment';
import {MCardEvent} from './mcard-events/mcard-event';
import {Router} from '@angular/router';

@Injectable()
export class ReloyaltyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: {enable: true, direction: Hammer.DIRECTION_ALL},
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FreeMoneyLayoutComponent,
    ActionsPreviewsListComponent,
    CampaignsRewardsListComponent,
    ValueRatingSlideInComponent,
    HowToRedeemComponent,
    RedeemedListStatsCardComponent,
    MapComponent,
    MapMarkerDetailsCarouselComponent,
    MapMarkerDetailsCardComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    GoogleMapsModule,
    LoyaltyCurrencyPipe,
    LoyaltyNumberPipe,
    AppRoutingModule,
    MerchantAutocompleteComponent,
    CampaignCardPromoDetailsComponent,
    MoneyFormatPipe,
    RadioButtonsQuestionComponent,
    NgOptimizedImage,
    ActionCardPreviewComponent,
    TranslocoRootModule,
    MaterialModule,
    TranslocoDirective,
    ClosableBottomSheetHandleComponent,
  ],
  exports: [],
  providers: [provideAppInitializer(() => {
    const initializerFn = (preloadTransloco)(inject(TranslocoService));
    return initializerFn();
  }), {
    provide: AuthService,
    useClass: CustomAuthService
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
    {
      provide: AUTH_CONFIG,
      useValue: {
        publicEndpoints: [`${environment.apiUrl}/auth/reset-password`],
        notAuthenticatedRedirectRoute: "/mcards/authentication",
        authenticatedRedirectRoute: "/"
      } as AuthConfig
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: ReloyaltyHammerConfig
    },
    LoyaltyNumberPipe,
    LoyaltyCurrencyPipe,
    CurrencyPipe,
    provideHttpClient(withInterceptorsFromDi()),
    {provide: 'environment', useValue: environment}],

  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, mCardEventService: MCardEventsService, router: Router) {
    addSvgIcons(iconRegistry, domSanitizer,
      [
        'file-list-gray', 'pause-icon', 'close-icon', 'stack-icon-gray', 'play-icon-white', 'plus-icon-gray',
        'dollar-icon', 'arrow-down-s-line', 'clock-Icon', 'location-Icon', 'arrow-increasing', 'arrow-increasing',
        'arrow-down-dark', 'features-icon', 'clock-thin-icon', 'link-accounts-icon', 'paper-plane', 'arrow-left-black',
        'list-icon', 'map-icon', 'mobile-connectivity-icon', 'wifi-icon', 'battery-icon', 'plus-circle', 'shop-icon',
        'document-with-dollar', 'dollar-raising-arrow', 'document-with-hand', 'mail-ai-line', 'exclamation-point', 'play-icon-blue'
      ]);

    this.registerMCardEventListeners(mCardEventService, router);
  }

  private registerMCardEventListeners(mCardEventService: MCardEventsService, router: Router): void {
    // Initialize MCard event listener
    mCardEventService.init(Object.values(MCardEvent));

    mCardEventService.subscribe(MCardEvent.FREE_MONEY_REDEEMED, (data: any) => {
      const _ = router.navigate(['congratulations', 'redeemed'], {queryParams: {campaignId: data.campaignId}});
    });
  }
}

export function preloadTransloco(transloco: TranslocoService) {
  return function () {
    return firstValueFrom(transloco.load(transloco.getDefaultLang()));
  };
}
