<ng-container *ngIf="itemKeys">
  <div *ngIf="itemMap" class="carousel">
    <div class="carousel__scroller">
      @for (groupNumber of itemKeys(); track groupNumber) {
        <section
          class="carousel__group"
          id="{{ componentId }}-group-{{ groupNumber }}">
          <ng-container>
            <app-map-marker-details-card class="carousel__item" [campaign]="itemMap().get(groupNumber)!"
                                         [campaignUserStatus]="campaignsUserStatus()"
                                         (swipeleft)="navToNext()"
                                         (swiperight)="navToPrev()"></app-map-marker-details-card>
          </ng-container>
        </section>
      }
    </div>
  </div>
</ng-container>
