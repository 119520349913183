import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {DataSourceFilter, Page, PageRequest, PageSort, serializeSort} from '../model';
import {CustomHttpParamEncoder} from '../../utils/custom-param-codec';

export interface FileResponse {
  data: Blob | null;
  contentType: string | null;
  fileName: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class RestClientService {

  constructor(private http: HttpClient,  @Inject('environment') private environment: any) {
  }

  private getUrl(url: string): string {
    let fullUrl = url;
    if (url && !url.startsWith('http')) {
      fullUrl = this.environment.apiUrl + url;
    }
    fullUrl += '?t=' + +new Date();
    return fullUrl;
  }

  get<T>(url: string, options?: {}): Observable<T> {
    return this.http.get<T>(this.getUrl(url), options);
  }

  getWithParams<T>(url: string, params: HttpParams): Observable<T> {
    return this.http.get<T>(this.getUrl(url), {params});
  }

  getPage<T>(url: string, request: PageRequest<any>): Observable<Page<T>> {
    let httpParams = this.buildPaginationParams(request.page, request.size, request.sort);
    return this.http.get<Page<T>>(this.getUrl(url), {params: httpParams});
  }

  getPageWithFilter<T>(url: string, request: PageRequest<T>, filter?: DataSourceFilter): Observable<Page<T>> {
    let httpParams = this.buildPaginationParams(request.page, request.size, request.sort);
    httpParams = this.addFilterParams(httpParams, filter)
    return this.http.get<Page<T>>(this.getUrl(url), {params: httpParams});
  }

  getListWithFilter<T>(url: string, filter?: DataSourceFilter): Observable<T[]> {
    let httpParams = new HttpParams();
    httpParams = this.addFilterParams(httpParams, filter)
    return this.http.get<T[]>(this.getUrl(url), {params: httpParams});
  }

  getHtml(url: string): Observable<string> {
    return this.http.get(this.getUrl(url), {responseType: 'text'});
  }

  getBlob(url: string, params?: HttpParams): Observable<FileResponse> {
    return this.http.get<Blob>(this.getUrl(url), {
      responseType: 'blob' as 'json',
      params,
      observe: 'response'
    })
      .pipe(switchMap((res) => {
        const contentDisposition = res.headers.get('Content-Disposition');
        const fileName = contentDisposition != null ? this.parseFilenameFromContentDisposition(contentDisposition) : null;
        return of({
          data: res.body,
          contentType: res.headers.get('Content-Type'),
          fileName
        });
      }));
  }

  post<T>(url: string, data: any, options?: {}): Observable<T> {
    return this.http.post<T>(this.getUrl(url), data, options);
  }


  postBlob(url: string, data: any, options?: {}, params?: HttpParams): Observable<FileResponse> {
    return this.http.post<Blob>(this.getUrl(url), data, {
      responseType: 'blob' as 'json',
      params,
      observe: 'response'
    })
      .pipe(switchMap((res) => {
        const contentDisposition = res.headers.get('Content-Disposition');
        const fileName = contentDisposition != null ? this.parseFilenameFromContentDisposition(contentDisposition) : null;
        return of({
          data: res.body,
          contentType: res.headers.get('Content-Type'),
          fileName
        });
      }));
  }

  put<T>(url: string, data: any, options?: {}): Observable<T> {
    return this.http.put<T>(this.getUrl(url), data, options);
  }

  patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(this.getUrl(url), data);
  }

  delete<T>(url: string, options?: {}): Observable<T> {
    return this.http.delete<T>(this.getUrl(url), options);
  }

  postWithFiles<T>(url: string, data: any, files?: UploadFile[], options?: {}): Observable<T> {
    const formData = this.prepareFormData(data, files);
    return this.http.post<T>(this.getUrl(url), formData, options);
  }

  putWithFiles<T>(url: string, data: any, files: UploadFile[], options?: {}): Observable<T> {
    const formData = this.prepareFormData(data, files);
    return this.http.put<T>(this.getUrl(url), formData, options);
  }

  public addFilterParams(httpParams: HttpParams, filter?: DataSourceFilter): HttpParams {
    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          httpParams = httpParams.append(key, value);
        }
      });
    }
    return httpParams;
  }

  private prepareFormData(data: any, files?: UploadFile[]): FormData {
    const formData = new FormData();
    formData.append('model', new Blob([JSON.stringify(data)], {type: 'application/json'}));
    if (files != null && files.length > 0) {
      for (const file of files) {
        formData.append(file.name, file.file);
      }
    }
    return formData;
  }

  private buildPaginationParams<T>(pageIndex: number = 0, pageSize: number = 10, customSort?: PageSort<T>): HttpParams {
    let httpParams = new HttpParams({encoder: new CustomHttpParamEncoder()});
    httpParams = httpParams.append('size', `${pageSize}`);
    httpParams = httpParams.append('page', `${pageIndex}`);
    if (customSort?.property && customSort?.direction) {
      httpParams = httpParams.append('sort', serializeSort(customSort));
    }

    return httpParams;
  }

  private parseFilenameFromContentDisposition(contentDisposition: string): string | null {
    const match = contentDisposition.match(/inline; filename=(.*)/);
    return match != null ? match[1] : null;
  }
}

export interface UploadFile {
  file: File;
  name: string
}
