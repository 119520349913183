<div class="map-marker-card-details-container" *transloco="let t">
  <div class="brand-container">
    <img class="merchant-logo"
         ngSrc="{{campaign().merchant.logoUrl || 'assets/images/brand-default.png'}}" alt="brand-logo"
         height="32" width="32">
    <div class="brand-title">{{ campaign().merchant.name || '' }}</div>
  </div>
  <div class="promo-title">
    {{
      t('actions.card.promoMessage.spentOnSpecificBrand', {
        reward: campaign().reward | loyaltyNumber,
        merchantName: 'here'
      })
    }}
  </div>
  <app-campaign-card-promo-details [campaign]="campaign()" [campaignUserStatus]="campaignUserStatus()" [lightFont]="true"></app-campaign-card-promo-details>
  <div class="buttons-container">
    <button mat-stroked-button class="button-medium button-outlined"
            (click)="openMapApp(address().latitude, address().longitude)">
      {{ t('actions.card.newActionPreviewDetails.buttons.getDirections') }}
    </button>
    @if (campaign().id && campaignUserStatus() != CampaignUserStatus.REDEEMED) {
      <button mat-stroked-button class="button-medium button-outlined" (click)="openHowToRedeem()">
        {{ t('actions.card.newActionPreviewDetails.buttons.howToRedeem') }}
      </button>
    }
  </div>
</div>
