<div #actionPreviewContainer class="actions-previews-container">
  @if (campaigns$ | async; as campaigns) {
    @for (campaign of campaigns; track index; let index = $index) {
      <div class="campaigns-container">
          <app-action-card-preview [campaignDetails]="campaign"
                                   [campaignUserStatus]="campaignUserStatus()"
                                   (valueRatingOpened)="openValueRatingModal()"
                                   (howToRedeemOpened)="openHowToRedeemModal(campaign)">
          </app-action-card-preview>
      </div>
    }
  }
</div>
