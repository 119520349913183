<div *transloco="let t" class="value-rating-container" (swipedown)="close()">
    <app-closable-bottom-sheet-handle (close)="close()"></app-closable-bottom-sheet-handle>
    <div class="title">{{t('mobile.valueRating.title')}}</div>
    <div class="text">{{t('mobile.valueRating.text')}}</div>
    <div class="coins-container">
        <img ngSrc="assets/images/coins/value-rating-1.svg" alt="coin icon" height="54" width="54">
        <img ngSrc="assets/images/coins/value-rating-2.svg" alt="coin icon" height="58" width="66">
        <img ngSrc="assets/images/coins/value-rating-3.svg" alt="coin icon" height="54" width="70">
        <img ngSrc="assets/images/coins/value-rating-4.svg" alt="coin icon" height="54" width="78">
        <img ngSrc="assets/images/coins/value-rating-5.svg" alt="coin icon" height="54" width="78">
    </div>
</div>
