import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet} from "@angular/material/bottom-sheet";
import {HowToRedeemData} from "../models/how-to-redeem-data";
import {ClosableBottomSheetComponent} from "shared-lib";

@Component({
  selector: 'app-how-to-redeem',
  templateUrl: './how-to-redeem.component.html',
  styleUrl: './how-to-redeem.component.scss',
  standalone: false
})
export class HowToRedeemComponent extends ClosableBottomSheetComponent {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: HowToRedeemData,
              bottomSheet: MatBottomSheet) {
    super(bottomSheet)
  }

}
