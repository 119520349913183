<div class="redeemed-list-stats-container" *transloco="let t">
  <div class="cell">
    <div class="value">{{ amountSaved() | loyaltyCurrency }}</div>
    <div class="label">{{ t('mobile.redeemedCampaignsStats.amountSaved') }}</div>
  </div>
  <div class="cell">
    <div class="value">{{ numberOfRedeemedCampaigns() | loyaltyNumber }}</div>
    <div class="label">{{ t('mobile.redeemedCampaignsStats.redeemed') }}</div>
  </div>
  <div class="cell">
    <div class="value">{{ percentSaved() }}%</div>
    <div class="label">{{ t('mobile.redeemedCampaignsStats.percentSaved') }}</div>
  </div>
</div>
