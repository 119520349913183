import {NestedKeyOf} from '../../utils/type-utils';

export interface Page<T> {
  content: T[];
  page: {
    totalElements: number;
    totalPages: number;
    size: number;
    number: number;
  }
}

export interface PageSort<T> {
  property?: NestedKeyOf<T>;
  direction?: 'asc' | 'desc';
}

export function serializeSort<T>(sort: PageSort<T>): string {
  if (sort.property && sort.direction) {
    return `${sort.property},${sort.direction}`;
  } else {
    return '';
  }
}

export function deserializeSort<T>(sort: string): PageSort<T> {
  const sortTokens = sort.split(',');
  if (sortTokens.length == 2) {
    return {property: sortTokens[0] as any, direction: sortTokens[1] as any};
  } else {
    return {};
  }
}

export interface PageRequest<T> {
  page: number;
  size: number;
  sort?: PageSort<T>;
}

export type DataSourceFilter = { [n: string]: string | number | undefined | boolean };

export const PAGE_SIZE_5 = 5;
export const PAGE_SIZE_20 = 20;
