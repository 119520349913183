import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FreeMoneyLayoutComponent} from "./components/free-money-layout/free-money-layout.component";
import {AuthGuard} from './auth/auth-guard';
import {MCardsLoginComponent} from 'shared-lib';
import {Application} from '../../../shared-lib/src/lib/services/auth';

const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  children: [
      {
        path: '', component: FreeMoneyLayoutComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'congratulations',
        loadChildren: () => (import('./modules/congratulations/congratulations.module')).then(m => m.CongratulationsModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'mcards/authentication',
    component: MCardsLoginComponent,
    data: { application: Application.FREE_MONEY }
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
