import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import {Observable} from "rxjs";
import {Inject, Injectable} from "@angular/core";
import {AUTH_CONFIG, AuthConfig, AuthService, User} from 'shared-lib';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router, @Inject(AUTH_CONFIG) private authConfig: AuthConfig) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.getCurrentUser();
    if (user) {

      const hasRole = this.checkUserRole(route, user);
      if (hasRole) {
        return true;
      }
    }

    const _ = this.router.navigate([this.authConfig.notAuthenticatedRedirectRoute],
      {state: {redirectUrl: this.getResolvedUrl(route), queryParams: route.queryParams}});
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  private checkUserRole(route: ActivatedRouteSnapshot, user: User) {
    const requiredRoles = route.data['roles'];
    if (requiredRoles) {
      for (let requiredRole of requiredRoles) {
        if (user.roles.includes(requiredRole)) {
          return true;
        }
      }
      return false;
    } else {
      return this.authService.hasAuthenticatedUser();
    }
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(activatedRouteSnapshot => activatedRouteSnapshot.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

}

