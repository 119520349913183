import {Component, computed, Inject, signal} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet} from '@angular/material/bottom-sheet';
import {CampaignService} from '../../services/campaign.service';
import {Campaign, ClosableBottomSheetComponent, getDaysLeft, TimeConditionsType} from 'shared-lib';

@Component({
  selector: 'app-campaigns-rewards-list',
  templateUrl: './campaigns-rewards-list.component.html',
  styleUrl: './campaigns-rewards-list.component.scss',
  standalone: false
})
export class CampaignsRewardsListComponent extends ClosableBottomSheetComponent {

  campaigns = signal<Campaign[]>([]);
  daysLeft = computed<Map<string, number>>(() => {
    const result = new Map<string, number>;
    this.campaigns()
      .filter(campaign => campaign.timeConditions?.timeConditionsType === TimeConditionsType.PERIOD)
      .forEach(campaign => {
        result.set(campaign.id, getDaysLeft(campaign.timeConditions!.endDate!));
      });
    return result;
  });

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: CampaignsRewardsListComponentData,
              campaignService: CampaignService, bottomSheet: MatBottomSheet) {
    super(bottomSheet);
    campaignService.getAllActiveCampaigns().subscribe(result => this.campaigns.set(result));
  }

}

export interface CampaignsRewardsListComponentData {
  rewardsSum: number;
}
