<div class="campaigns-rewards-list-container" *transloco="let t">
  <div class="rewards-info-container" (swipedown)="close()">
    <app-closable-bottom-sheet-handle (close)="close()"></app-closable-bottom-sheet-handle>
    <div class="total-promo-amount">{{ data.rewardsSum | loyaltyCurrency }}</div>
    <div class="subtitle">{{ t('mobile.campaignsRewardList.infoSubtitle') }}</div>
  </div>

  <div class="table">
    <div class="row">
      <div class="first-column title">{{ t('mobile.campaignsRewardList.amount') }}</div>
      <div class="second-column title">{{ t('mobile.campaignsRewardList.brand') }}</div>
    </div>
    <div class="unscrollable-container">
      <div class="campaigns-container">
        @for (campaign of campaigns(); track campaign.id) {
          <div class="row">
            <div class="first-column campaign">{{ campaign.reward | loyaltyCurrency }}</div>
            <div class="second-column campaign">
              <img alt="logo" [ngSrc]="campaign.merchant.logoUrl ?? 'assets/images/brand-default.png'" width="24"
                   height="24">
              <div>{{ campaign.merchant.name }}</div>
              @if (daysLeft().get(campaign.id)) {
                <div class="days-left" [ngClass]="{warn: daysLeft().get(campaign.id)! < 2}">
                  {{ t('mobile.campaignsRewardList.daysLeft', {daysLeft: daysLeft().get(campaign.id) | loyaltyNumber}) }}
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
