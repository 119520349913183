<div class="map-container">
  <google-map
    mapId="ddd9498ff818dc08"
    height="100%"
    width="100%"
    [options]="mapOptions"
    [center]="center"
    [zoom]="zoom"
    (mapClick)="onMapClick()"
    (mapInitialized)="onMapReady($event)">
    <map-advanced-marker [position]="userPositionMarker.position"
                         (mapClick)="clearSelection()"/>
    <map-marker-clusterer (markerClustererInitialized)="googleMarkerCluster = $event">
      @for (marker of markers(); track marker; let index = $index) {
        <map-advanced-marker [id]="'marker' + index" [options]="marker"
                             (mapClick)="onMapMarkerClick($event, marker)" (markerInitialized)="onMarkerInitialized($event, index)"/>
      }
    </map-marker-clusterer>
  </google-map>
  <app-map-marker-details-carousel class="carousel" [campaigns]="campaigns()"
                                   [campaignsUserStatus]="campaignUserStatus()"
                                   [selectedCampaign]="selectedCampaign"
                                   (selectedCampaignChange)="onSelectedCampaignChange($event)"
                                   [ngStyle]="{'visibility':this.campaigns().length > 0 && selectedCampaign ? 'visible' : 'hidden'}"></app-map-marker-details-carousel>
</div>
