import {Injectable} from '@angular/core';
import {RestClientService} from '../rest-client/rest-client.service';
import {Observable} from 'rxjs';
import {AuthenticationTokens} from '../model';
import {LoginModel} from '../model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private restClient: RestClientService) {
  }

  loginWithCredentials(email: string, password: string): Observable<AuthenticationTokens> {
    return this.restClient.post<AuthenticationTokens>('/login', {
      username: email,
      password: password
    } as LoginModel);
  }

  loginWithMcardsTokenToken(token: string, application: Application): Observable<AuthenticationTokens> {
    return this.restClient.post<AuthenticationTokens>(`/mcards/authentication`, {token, application});
  }

}

export enum Application {
  LOYALTY= 'LOYALTY',
  FREE_MONEY = 'FREE_MONEY'
}

export const userCardId = 'b19f86cd-3896-44dd-8ea9-591861793f86';

//TODO see how we are receive user position
export const userPosition = {
  lat: 40.776458, lng: -73.955983
} as any;
